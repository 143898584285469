<template>
    <div class="pc-container">
        <div class="content">
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 21.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL
                        Privacy Policy</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;</span></strong>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">[Last
                        revised: 24 May 2019]</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">This
                    Privacy Policy explains how </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;and
                    its Affiliates (&ldquo;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&rdquo;)
                    collects, uses, shares, and protects user information obtained through the </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.com
                    website. The terms &ldquo;we,&rdquo; &ldquo;us,&rdquo; and &ldquo;our&rdquo; refer to </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;and
                    its affiliates. When we ask for certain personal information from users it is because we are required by law to
                    collect this information or it is relevant for specified purposes. Any non-required information you provide to
                    us is done so voluntarily. You decided whether to provide us with these non-required information; you may not be
                    able to access or utilize all of our Services if you choose not to.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">By
                    using the Site, you consent to the data practices described in this Privacy Policy. On occasion, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;may
                    revise this Privacy Policy to reflect changes in law or our personal data collection and use practices. If
                    material changes are made to this Privacy Policy, the changes will be announced by posting on the site. We will
                    ask for your consent before using your information for any purpose that is not covered in this Privacy
                    Policy.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">The
                    latest privacy policy has incorporated elements from the General Data Protection Regulation (GDPR) as we act in
                    accordance to its personal information processing rules within the European Economic Area (EEA).</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">INFORMATION
                    </span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;COLLECTS</span></strong>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                        want you to understand the types of information we collect when you register for and use
                    </span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&rsquo;s
                        services</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Information
                    you provide to us at registration</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">When
                    you create a </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Account,
                    you provide us with personal information that includes your contact information (Email Address, name, and a
                    password). You can also choose to add a phone number for SMS or Google Authenticator account to be used for 2FA
                    verification for improved security.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Information
                    we collect when authenticating user identity</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">To
                    comply with global industry regulatory standards including Anti-Money Laundering (AML), and Counter Terrorist
                    Financing (CTF), </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;requires
                    user accounts to undergo user identity authentication for both Personal &amp; Enterprise-level accounts. This
                    entails collecting formal identification.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Information
                    we collect as you use our services</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Service
                        Usage Information</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Through
                    your use of the </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;platform,
                    we also monitor and collect tracking information related to usage such as access date &amp; time, device
                    identification, operating system, browser type and IP address. This information may be directly obtained by
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;or
                    through third party services. This service usage data helps us our systems to ensure that our interface is
                    accessible for users across all platforms and can aid during criminal investigations.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Transaction
                        Information</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">For
                    all personal and enterprise user accounts, we collect transaction information including deposit snapshots,
                    account balances, trade history, withdrawals, order activity and distribution history. This transaction data is
                    monitored for suspicious trading activity for user fraud protection, and legal case resolution.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">WHY
                        DOES </span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;COLLECT
                        THIS INFORMATION</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">To
                        provide and maintain our services</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                    use the information collected to deliver our services and verify user identity.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                    use the IP address and unique identifiers stored in your device&rsquo;s cookies to help us authenticate your
                    identity and provide our service. Given our legal obligations and system requirements, we cannot provide you
                    with services without data like identification, contact information and transaction-related information.</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">To
                        protect our users</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                    use the information collected to protect our platform, users&rsquo; accounts and archives.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                    use IP addresses and cookie data to protect against automated abuse such as spam, phishing and Distributed
                    Denial of Service (DDoS) attacks.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                    analyse trading activity with the goal of detecting suspicious behavior early to prevent potential fraud and
                    loss of funds to bad actors.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">To
                        comply with legal and regulatory requirements</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Respect
                    for the privacy and security of data you store with </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;informs
                    our approach to complying with regulations, governmental requests and user-generated inquiries. We will not
                    disclose or provide any personal information to third party sources without review from our legal case team
                    and/or prior consent from the user.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">To
                        measure site performance</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                    actively measure and analyse data to understand how our services are used. This review activity is conducted by
                    our operations team to continually improve our platform&rsquo;s performance and to resolve issues with the user
                    experience.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                    continuously monitor our systems&rsquo; activity information and communications with users to look for and
                    quickly fix problems.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">To
                        communicate with you</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                    use personal information collected, like an email address to interact with users directly when providing
                    customer support on a ticket or to keep you informed on log ins, transations, and security. Without processing
                    your personal information for confirming each communication, we will not be able to respond to your submitted
                    requests, questions and inquiries. All direct communications are kept confidential and reviewed internally for
                    accuracy.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">To
                        enforce our Terms of Use and other agreements</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">It
                    is very important for us and our customers that we continually review, investigate and prevent any potentially
                    prohibited or illegal activities that violate our Terms of Service. For the benefit of our entire user base, we
                    carefully enforce our agreements with third parties and actively investigate violations of our posted Terms of
                    Use. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;reserves
                    the right to terminate the provision of service to any user found engaging in activities that violate our Terms
                    of Use.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">HOW
                        DOES </span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;PROTECT
                        USER DATA</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;has
                    implemented a number of security measures to ensure that your information is not lost, abused, or altered. Our
                    data security measures include, but are not limited to: PCI Scanning, Secured Sockets Layered encryption
                    technology, pseudonymisation, internal data access restrictions, and strict physical access controls to
                    buildings &amp; files. Please note that it is impossible to guarantee 100% secure transmission of data over the
                    Internet nor method of electronic storage. As such, we request that you understand the responsibility to
                    independently take safety precautions to protect your own personal information.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">If
                    you suspect that your personal information has been compromised, especially account and/or password information,
                    please lock your account and contact </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NEODEAL</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;customer
                    service immediately.</span></p>
            <p class="MsoNormal"><span
                    style="mso-spacerun: 'yes'; font-family: Calibri; mso-fareast-font-family: 宋体; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">&nbsp;</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {
            
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        width: 100%;
        box-sizing: border-box;
        padding: 40px $box-padding;
        background-color: #f6f6f6;
        
        .content {
            width: 100%;
            word-wrap: break-word;
        }
    }
</style>